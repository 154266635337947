<template>
  <div class="w-10/12 lg:w-9/12 text-left font-proximaNormal">
    <h2 class="text-2xl">{{ $t("registration_title") }}</h2>
    <h5 class="text-sm my-4" v-if="account.role != 2">
      {{ $t("registration_info_text") }}
    </h5>
    <div>
      <FormError :errors="errorFormPhone" />
      <div class="relative mb-1">
        <!--  validCharactersOnly
          autoformat
          mode="international" -->
        <vue-tel-input
          required
          :inputOptions="options"
          v-model="phone"
          validCharactersOnly
          mode="international"
          @input="handleInput"
          class="rounded-md"
          :class="{
            'border border-red': errorFormPhone,
            'border-0': !errorFormPhone,
          }"
        />
      </div>
      <FormError :errors="errorFormEmail" />
      <div class="relative">
        <MailIcon class="absolute left-4 top-4" />
        <input
          type="text"
          :placeholder="$t('registration_email_field_hint')"
          v-model="account.email"
          class="w-full rounded-md px-12 py-3 my-1 text-sm"
          :class="{
            'border border-red': errorFormEmail,
            'border-0': !errorFormEmail,
          }"
        />
      </div>
    </div>
    <div class="mt-2 text-xs font-proximaNormal">
      <div class="flex items-start">
        <input
          class="mt-1"
          type="checkbox"
          v-model="checked"
          v-on:input="tosCheck = false"
        />
        <p class="ml-2 mt-1">
          {{ $t("sign_up_read_text_begin") }}
          <span
            class="text-right text-blue cursor-pointer"
            @click="tosCheck = !tosCheck"
            >{{ $t("global_read") }}</span
          >
          {{ $t("sign_up_read_text_end") }}
        </p>
      </div>
      <AccordionSection :tosCheck="tosCheck" />
    </div>
    <SubmitButton
      :title="$t('registration_register_button')"
      background="bg-lightGrey"
      class="cursor-not-allowed"
      v-if="!checked"
    />
    <SubmitButton
      :title="$t('registration_register_button')"
      background="bg-primary"
      @click.native="submitForm"
      v-if="checked && !showLoaderComp"
    />
    <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" />
  </div>
</template>

<script>
import PhoneIcon from "../icons/PhoneIcon.vue";
import MailIcon from "../icons/MailIcon.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import FormError from "../warning/FormError.vue";
import Loader from "../loader/Loader.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import AccordionSection from "../accordion/AccordionSection.vue";

import { mapActions, mapGetters } from "vuex";

import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
import { errorMixin } from "../../errorMixin.js";
//import VuePhoneNumberInput from "vue-phone-number-input";

export default {
  mixins: [errorMixin],
  props: {
    servicePracticeType: Number,
  },
  components: {
    ModalsConfirm,
    AccordionSection,
    PhoneIcon,
    MailIcon,
    SubmitButton,
    FormError,
    Loader,
    VueTelInput,
    //VuePhoneNumberInput,
  },
  data() {
    return {
      account: {
        phonenumber: "",
        email: "",
        lang: "EN",
        role: localStorage.getItem("role"),
        servicePracticeType: this.servicePracticeType,
      },
      tosCheck: true,
      errors: {},
      checked: false,
      phone: "",
      country: "",
      options: {
        placeholder: this.$t("global_phone_number_placeholder"),
        showDialCode: true,
        formatOnDisplay: true,
        autoFormat: true,
      },
    };
  },
  computed: {
    errorFormEmail() {
      return this.$store.state.errorFormEmail;
    },
    errorFormPhone() {
      return this.$store.state.errorFormPhone;
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
    showLoaderComp() {
      return this.$store.state.showLoaderState;
    },
  },

  methods: {
    ...mapActions({
      sendOtp: "registrationlogin/sendOtp",
      send_MagicLink_Registration:
        "registrationlogin/send_MagicLink_Registration",
      verify_email_phoneNumber: "registrationlogin/verify_email_phoneNumber",
    }),

    countryChanged(country) {
      this.country = country.dialCode;
    },

    telValidate(telnumber) {
      console.log(telnumber, ": 8888");

      this.account.phoneNo = telnumber.number;
    },
    handleInput(value) {
      this.account.phonenumber = value;
    },

    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validate() {
      const errors = {};

      if (this.phone === "")
        this.$store.commit("errorFormPhone", "Phone number is required");
      if (this.account.email === "")
        this.$store.commit("errorFormEmail", "Email  is required");
      if (!this.validEmail(this.account.email))
        this.$store.commit("errorFormEmail", "Valid email is required.");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitForm(e) {
      this.$store.commit("errorFormEmail", "");
      this.$store.commit("errorFormPhone", "");
      this.$store.commit("errorForm", "");

      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      console.log(this.account);
      this.account.email = this.account.email.toLowerCase();

      if (localStorage.getItem("referral") != "") {
        this.account.referral = localStorage.getItem("referral");
      }
      if (localStorage.getItem("ruleID")) {
        this.account.ruleID = localStorage.getItem("ruleID");
      }
      this.verify_email_phoneNumber(this.account);
    },
  },
};
</script>

<style>
.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.375rem 0 0 0.375rem;
}

.vue-tel-input {
  border-radius: 0.375rem;
  border: none;
  font-size: 0.875rem;
}

.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.375rem 0.375rem 0;
}

.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
.vti__dropdown-list.below {
  z-index: 3;
}
</style>

<template>
  <div class="my-6 md:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft />
    <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pr-16 lg:pr-32">
      <SignupSection
        :servicePracticeType="
          this.$route.params.servicePracticeType
            ? this.$route.params.servicePracticeType
            : 0
        "
      />
    </div>
    <DataserviceLogo />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import SignupSection from "../components/primaryregistration/SignupSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue";

export default {
  components: {
    SectionLeft,
    SignupSection,
    DataserviceLogo,
  },
  created() {
    console.log(
      "this.$route.params : ---- ",
      this.$route.params,
      this.$route.params.role
    );
    if (!localStorage.getItem("role")) {
      this.$route.params.role
        ? (localStorage.setItem("role", this.$route.params.role),
          console.log("********************"))
        : this.$router.push({ name: "Join" });
    }
  },
};
</script>

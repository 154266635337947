<template>
  <div class="w-9/12">
    <div class="w-full mt-4 flex items-center">
      <div class="w-full border-b border-subheaderText"></div>
      <span class="px-6 text-sm font-proximaNormal">{{ $t("global_or") }}</span>
      <div class="w-full border-b border-subheaderText"></div>
    </div>
    <div class="mt-4 flex items-center justify-center">
      <!-- <p>{{ googleLink }}</p> -->
      <a :href="this.googleLink">
        <img
          src="@/assets/images/google-icon.png"
          class="mx-3 cursor-pointer"
          alt="google icon"
        />
      </a>

      <!-- <a :href="this.twitterLink">
        <img
          src="@/assets/images/twitter-icon.png"
          class="mx-3 cursor-pointer"
          alt="twitter icon"
        />
      </a> -->
    </div>
  </div>
</template>

<script>
import { apiUrl } from "../../config.json";
export default {
  data() {
    return {
      role: "",
      twitterLink: `${apiUrl}authentication/registration/twitter/?role=${this.role}`,
      googleLink: `${apiUrl}authentication/registration/google/?role=${localStorage.getItem(
        "role"
      )}`,
    };
  },
  created() {
    this.role = localStorage.getItem("role");
  },
};
</script>

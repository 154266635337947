<template>
  <div class="about flex flex-col justify-center items-center">
    <ul class="w-full font-proximaMedium">
      <li v-for="(data, i) in tos" :key="i">
        <input type="checkbox" :checked="tosCheck" />
        <i></i>
        <h2>{{ data.title }}</h2>
        <p v-html="outlineHtml"></p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["tosCheck"],
  data() {
    return {
      tos: [
        {
          id: "1",
          title: "Terms and Conditions",
          content: [
            { html: "<h1>I. Introduction</h1>" },
            {
              html: '<p class="font-proximaLight">These Terms of Service (the "Terms") lay down the rules for using the perPETual platform. Using our platform means you accept these Terms, which we may update occasionally.</p>',
            },
            { html: "<h1>II. User Obligations</h1>" },
            {
              html: '<p class="font-proximaLight">To sign up to the perPETual platform, you must be at least 16 years old, as per EU law, and ensure your account details are accurate. Respect other users and the platform—no illegal activities, spamming or offensive content. Any data or material you post remains yours, but by posting, you give us permission to use it on our platform.</p>',
            },
            { html: "<h1>III. Platform Usage</h1>" },
            {
              html: '<p class="font-proximaLight" >Treat others with kindness, and help us keep PerPETual an informative and inclusive space for all pet owners. Learn how we protect your data in our Privacy Policy. While we aim for continuous service, occasional downtime might occur for maintenance or due to circumstances beyond our control. And remember, we are not responsible for content on other websites we might link to.</p>',
            },
            {
              html: "<h1>IV. Legal Disclaimers and Limitations</h1>",
            },
            {
              html: '<p class="font-proximaLight" >The PerPETual platform is offered "as is" and "as available," with no guarantees. Except as prohibited by EU consumer law, we are not liable for any damages from using or not being able to use our platform.</p>',
            },
            { html: "<h1>V. Miscellaneous</h1>" },
            {
              html: '<p class="font-proximaLight" >If any part of these Terms is unenforceable, the rest still apply. These Terms are the complete agreement between you and us. Questions or comments? [<a href="contact@perpetualkin.com">contact@perpetualkin.com</a>]. Thanks for joining our animal loving community!</p>',
            },
          ],
        },
      ],
    };
  },
  computed: {
    outlineHtml() {
      var content = this.tos[0].content;
      return content.map((item) => item.html).join("");
    },
  },
};
</script>

<style scoped>
.transition,
ul li i:before,
ul li i:after,
p {
  transition: all 0.25s ease-in-out;
}

.no-select,
h2 {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1,
h2 {
  color: #354e57;
}

h1 {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 3px;
  font-weight: 100;
}

h2 {
  font-size: 14px;
  line-height: 34px;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  /* background-color: #fefffa; */
  margin: 0;
  cursor: pointer;
}

p {
  color: rgba(48, 69, 92, 0.8);
  font-size: 12px;
  line-height: 26px;
  text-align: justify;
  position: relative;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 12px;
  padding: 0 4px;
  z-index: 2;
}

ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}
ul li {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 4px;
  padding-top: 10px;
  /* border-top: 1px dotted #dce7eb; */
}
ul li:nth-of-type(1) {
  animation-delay: 0.5s;
}
ul li:nth-of-type(2) {
  animation-delay: 0.75s;
}
ul li:nth-of-type(3) {
  animation-delay: 1s;
}
ul li:last-of-type {
  padding-bottom: 0;
}
ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 16px;
  right: 0;
}
ul li i:before,
ul li i:after {
  content: "";
  position: absolute;
  background-color: #354e57;
  width: 3px;
  height: 9px;
  right: 5px;
}
ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}
ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}
ul li input[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
ul li input[type="checkbox"]:checked ~ p {
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}
ul li input[type="checkbox"]:checked ~ i:before {
  transform: translate(2px, 0) rotate(45deg);
}
ul li input[type="checkbox"]:checked ~ i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
</style>

<template>
  <div class="flex flex-col justify-center items-center">
    <SignupHeader :step="$t('global_step_01_03')" :title="$t('global_header_registration_method')" back="join" />
    <div class="w-9/12">
      <img
        class="h-28 mb-12 mx-auto md:mx-0"
        src="@/assets/images/mail-icon-cl.png"
        alt="mail-icon-cl"
      />
    </div>
    <SignupForm :servicePracticeType="servicePracticeType" />
    <SignupSocial v-if="displaySocial" />
  </div>
</template>

<script>
import SignupForm from "./SignupForm.vue";
import SignupSocial from "./SignupSocial.vue";
import SignupHeader from "./SignupHeader.vue";

export default {
  props: {
    servicePracticeType: Number,

  },
  components: {
    SignupForm,
    SignupSocial,
    SignupHeader,
  },
  data() {
    return {
      displaySocial: true,
    };
  },
  created() {
    let role= localStorage.getItem("role");
    console.log(
      "servicePracticeType",
      this.servicePracticeType,
      "role",
      role
    );

    if (role == 4 || role == 5) {
      this.displaySocial = false;
    }
  },
  computed: {
    socialSignup() {
      return this.$store.state.regType;
    },
  },
};
</script>

<style></style>

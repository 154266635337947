<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9609 7.00257V1.00257C19.9609 0.737356 20.0662 0.483002 20.2538 0.295465C20.4413 0.107929 20.6957 0.00257217 20.9609 0.00257217C21.2261 0.00257217 21.4805 0.107929 21.668 0.295465C21.8555 0.483002 21.9609 0.737356 21.9609 1.00257V7.00257C21.9609 7.26779 21.8555 7.52214 21.668 7.70968C21.4805 7.89721 21.2261 8.00257 20.9609 8.00257C20.6957 8.00257 20.4413 7.89721 20.2538 7.70968C20.0662 7.52214 19.9609 7.26779 19.9609 7.00257ZM16.9609 8.00257C17.2261 8.00257 17.4805 7.89721 17.668 7.70968C17.8555 7.52214 17.9609 7.26779 17.9609 7.00257V1.00257C17.9609 0.737356 17.8555 0.483002 17.668 0.295465C17.4805 0.107929 17.2261 0.00257217 16.9609 0.00257217C16.6957 0.00257217 16.4413 0.107929 16.2538 0.295465C16.0662 0.483002 15.9609 0.737356 15.9609 1.00257V7.00257C15.9609 7.26779 16.0662 7.52214 16.2538 7.70968C16.4413 7.89721 16.6957 8.00257 16.9609 8.00257ZM23.0549 16.7406C23.0249 16.7096 20.6179 14.8576 20.6179 14.8576C20.0402 14.3074 19.2727 14.0012 18.475 14.0025C17.6773 14.0038 16.9108 14.3126 16.3349 14.8646L14.4349 16.4706C12.878 15.8272 11.4636 14.8825 10.2731 13.6906C9.08256 12.4987 8.13942 11.0832 7.49788 9.52557L9.09788 7.62557C9.64874 7.04959 9.9566 6.28357 9.95753 5.48657C9.95846 4.68958 9.65239 3.92284 9.10288 3.34557C9.10288 3.34557 7.25288 0.942572 7.22088 0.911572C6.65068 0.336407 5.87688 0.00897858 5.06703 0.000181914C4.25717 -0.00861476 3.47644 0.301929 2.89388 0.864572L1.74488 1.86457C-5.97612 10.0736 13.9449 30.0036 22.1449 22.1646L23.0569 21.1146C23.6346 20.5334 23.9587 19.7471 23.9583 18.9277C23.958 18.1082 23.6331 17.3212 23.0549 16.7406Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>
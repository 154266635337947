<template>
  <div class="w-9/12 pt-6 md:pt-12 mb-10 flex justify-between items-end">
    <div
      class="
        text-sm text-left
        md:text-right
        font-proximaLight
        flex
        justify-center
        items-center
        cursor-pointer
      "
      @click="routerPush"
    >
      <img rel="prefetch" src="@/assets/images/back-icon.png" alt="back-icon">
    </div>
    <div class="text-sm text-right font-proximaLight">
      <p>{{ step }}</p>
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["step", "title", "back"],
  methods: {
    routerPush() {
      this.$router.push(this.back)
    }
  }
};
</script>
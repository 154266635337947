var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-10/12 lg:w-9/12 text-left font-proximaNormal"},[_c('h2',{staticClass:"text-2xl"},[_vm._v(_vm._s(_vm.$t("registration_title")))]),(_vm.account.role != 2)?_c('h5',{staticClass:"text-sm my-4"},[_vm._v(" "+_vm._s(_vm.$t("registration_info_text"))+" ")]):_vm._e(),_c('div',[_c('FormError',{attrs:{"errors":_vm.errorFormPhone}}),_c('div',{staticClass:"relative mb-1"},[_c('vue-tel-input',{staticClass:"rounded-md",class:{
          'border border-red': _vm.errorFormPhone,
          'border-0': !_vm.errorFormPhone,
        },attrs:{"required":"","inputOptions":_vm.options,"validCharactersOnly":"","mode":"international"},on:{"input":_vm.handleInput},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('FormError',{attrs:{"errors":_vm.errorFormEmail}}),_c('div',{staticClass:"relative"},[_c('MailIcon',{staticClass:"absolute left-4 top-4"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.account.email),expression:"account.email"}],staticClass:"w-full rounded-md px-12 py-3 my-1 text-sm",class:{
          'border border-red': _vm.errorFormEmail,
          'border-0': !_vm.errorFormEmail,
        },attrs:{"type":"text","placeholder":_vm.$t('registration_email_field_hint')},domProps:{"value":(_vm.account.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.account, "email", $event.target.value)}}})],1)],1),_c('div',{staticClass:"mt-2 text-xs font-proximaNormal"},[_c('div',{staticClass:"flex items-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"mt-1",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,null)>-1:(_vm.checked)},on:{"input":function($event){_vm.tosCheck = false},"change":function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}}}}),_c('p',{staticClass:"ml-2 mt-1"},[_vm._v(" "+_vm._s(_vm.$t("sign_up_read_text_begin"))+" "),_c('span',{staticClass:"text-right text-blue cursor-pointer",on:{"click":function($event){_vm.tosCheck = !_vm.tosCheck}}},[_vm._v(_vm._s(_vm.$t("global_read")))]),_vm._v(" "+_vm._s(_vm.$t("sign_up_read_text_end"))+" ")])]),_c('AccordionSection',{attrs:{"tosCheck":_vm.tosCheck}})],1),(!_vm.checked)?_c('SubmitButton',{staticClass:"cursor-not-allowed",attrs:{"title":_vm.$t('registration_register_button'),"background":"bg-lightGrey"}}):_vm._e(),(_vm.checked && !_vm.showLoaderComp)?_c('SubmitButton',{attrs:{"title":_vm.$t('registration_register_button'),"background":"bg-primary"},nativeOn:{"click":function($event){return _vm.submitForm.apply(null, arguments)}}}):_vm._e(),(_vm.showLoaderComp)?_c('Loader',{staticClass:"mt-6 flex justify-center"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }